import {
  Grid,
  TextField,
  Typography,
  Button,
  Tab,
  IconButton,
  FormHelperText,
  InputAdornment,
  CircularProgress,
  Link,
  Tooltip
} from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import 'rsuite/dist/rsuite-no-reset.min.css';
import 'rsuite/dist/rsuite.css';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import colors from 'assets/scss/_themes-vars.module.scss';
// import SearchTable from './searchTable';
// import SelectFromTable from './selectFromTable';
import MUIDataTable from 'mui-datatables';
import { useState } from 'react';
import MainCard from 'ui-component/cards/MainCard';
import CloseIcon from '@mui/icons-material/Close';
import { useAppDispatch } from 'store';
import { useEffect } from 'react';
import { useAppSelector } from 'store';
import { getAdgorupCampaignList, postMultipleAdItemNewApi } from 'store/thunk/userThunk';
import { setCampaignDetails } from 'store/slices/userDetailsSlice';
// import EnterKeywordTable from './enterItemTable';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';

import EnterKeywordTable from '../editProduct/editAdgroupItems/enterItemTable';
import SelectFromTable from '../editProduct/editAdgroupItems/selectFromTable';
import SearchTable from '../editProduct/editAdgroupItems/searchTable';
import { PageValue } from 'store/constant';
import CustomAutocomplete from 'ui-component/multi-select/CustomAutocomplete';
import SuggestFromTable from '../editProduct/suggestedTable';

const ProductAdMultipleItems = (props) => {
  const { itemModal, onClose, updateBulkCampaignDetails } = props;
  const { campaignDetails, selectdItemData, adgroupList } = useAppSelector((state) => state?.userDetailsSlice);
  const [value, setValue] = useState('2');
  const [loading, setLoading] = useState(false);
  const [searchableData, setSearchableData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [selectedAdgroup, setSelectedAdgroup] = useState([]);
  const [keyword, setkeyword] = useState('');
  const [IsDropdownOpenCamSt, SetIsDropdownOpenCamSt] = useState(false);
  const [SearchText, setSearchText] = useState({ searchTextAdgroup: null });
  const [rowPerPage, setRowPerPage] = useState(25);
  const [error, setError] = useState({ adGroup: false, adItem: false });

  const dispatch = useAppDispatch();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (selectedAdgroup?.length > 0 && error.adGroup == true) {
      setError({ ...error, adGroup: false });
    }
  }, [selectedAdgroup]);

  useEffect(() => {
    if (selectedData?.length > 0 && error.adItem == true) {
      setError({ ...error, adItem: false });
    }
  }, [selectedData]);

  useEffect(() => {
    const id = itemModal?.selectedItemId;
    if (id) {
      dispatch(getAdgorupCampaignList({ id }));
    }
  }, [itemModal]);

  const renderLabel = (name, required) => {
    return (
      <Box>
        <Typography sx={{ padding: 0.5, display: 'flex' }}>
          {required && <Box sx={{ color: 'red', mr: 0.5 }}>*</Box>} {name}
        </Typography>
      </Box>
    );
  };

  const onButtonClick = (event) => {
    event.preventDefault();

    if (selectedAdgroup?.length > 0 && selectedData?.length > 0) {
      setLoading(true);
      const temp = selectedData?.map((item) => {
        return { item_id: item?.item_id, bid_amount: item.bid_amount ? item.bid_amount : '0.20' };
      });
      var adgroupIds = [];
      selectedAdgroup?.map((item) => {
        adgroupIds.push(item?.id);
      });

      const _request = {
        adgroup_id: adgroupIds,
        // campaign_id: itemModal?.selectedItemId,
        aditems: temp
      };

      dispatch(postMultipleAdItemNewApi({ _request })).then((res) => {
        const successCount = res?.payload?.data?.data?.successcount; //Total success count
        if (successCount > 0) {
          const adgroupIds = _request?.adgroup_id; //selected adgroup ids
          const successCmpIds = [];
          adgroupIds?.map((l) => {
            const getAdgroupDetails = adgroupList?.find((adg) => adg?.id === l);
            const adgroupCmpId = getAdgroupDetails?.campaign; //find adgroup campaign
            successCmpIds.push(adgroupCmpId); //push success campaign ids of each adgroup [may have duplicate campaign ids]
          });

          //Find the value to add how much value in each campaign ids [if there us duplicate campaign then it have more value to add]
          const countMap = successCmpIds.reduce((acc, id) => {
            acc[id] = (acc[id] || 0) + 1;
            return acc;
          }, {});
          const valuesMap = Object.fromEntries(
            Object.entries(countMap).map(([id, count]) => [id, (successCount / successCmpIds.length) * count])
          );

          //Find unique success ids
          const uniqueSuccessCmpIds = [...new Set(successCmpIds)];

          const newObj = {};
          uniqueSuccessCmpIds?.map((cmpId) => {
            const getCmp = campaignDetails?.results?.find((l) => l?.id === cmpId);
            const getKeywordCountofCmp = getCmp?.aditem_number || 0;
            const updatedKeyword = getKeywordCountofCmp + valuesMap[cmpId]; //Sum
            newObj[cmpId] = {
              aditem_number: updatedKeyword
            };
          });

          //Update to store
          const updatedCampaignDetails = updateBulkCampaignDetails(campaignDetails, newObj);
          dispatch(setCampaignDetails(updatedCampaignDetails));
        }
        setLoading(false);
        if (res.payload.status == 201) {
          onClose();
        }
      });
    } else {
      setError({ adGroup: selectedAdgroup?.length > 0 ? false : true, adItem: selectedData?.length > 0 ? false : true });
    }
  };

  const onAddAllClick = () => {
    var tempArray = [...selectedData];
    if (searchableData?.length >= 1) {
      searchableData?.map((item) => {
        const isItemAlreadyExists = tempArray.some((existingItem) => existingItem.id === item?.id);
        if (!isItemAlreadyExists) {
          tempArray.push({
            id: item?.id,
            product_name: item?.product_name,
            images: item?.images,
            item_id: item?.item_id,
            price: item?.price,
            bid_amount: '0.20',
            suggested_bid: item?.suggested_bid
          });
        }
      });
    } else {
      selectdItemData?.map((item) => {
        const isItemAlreadyExists = tempArray.some((existingItem) => existingItem.id === item?.id);
        if (!isItemAlreadyExists) {
          tempArray.push({
            id: item?.id,
            product_name: item?.product_name,
            images: item?.images,
            item_id: item?.item_id,
            price: item?.price,
            bid_amount: '0.20',
            suggested_bid: item?.suggested_bid
          });
        }
      });
    }
    setSelectedData(tempArray);
  };

  const selectedColumns = [
    {
      name: 'product_name',
      label: 'Item',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          const data = selectedData[tableMeta?.rowIndex];
          return (
            <>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {data?.images ? (
                  <Box component={'img'} sx={{ padding: 1, height: '40px', width: '40px' }} src={data?.images} />
                ) : (
                  <InsertPhotoIcon fontSize="large" sx={{ margin: '4px', color: colors.grey500 }} />
                )}
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  {/* <Typography sx={{}}>{data?.product_name}</Typography> */}

                  {data?.product_name?.length > 20 ? (
                    <>
                      <Tooltip
                        placement="top"
                        sx={{ fontSize: '10px' }}
                        title={
                          <Typography variant="body1" sx={{ fontSize: 16 }}>
                            {data?.product_name}
                          </Typography>
                        }
                        arrow
                      >
                        {data?.product_name?.substring(0, 25)}...
                      </Tooltip>
                    </>
                  ) : (
                    data?.product_name
                  )}

                  <Link
                    sx={{ cursor: 'pointer' }}
                    onClick={() => window.open(`https://www.walmart.com/ip/${data?.item_id}`, '_blank')}
                  >
                    <Typography sx={{ color: colors.primaryMain }}>{data?.item_id ? data?.item_id : '123456789'}</Typography>
                  </Link>
                </Box>
              </Box>
            </>
          );
        }
      }
    },
    {
      name: 'suggested_bid',
      label: 'Suggested Bid',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'bid_amount',
      label: 'Bid',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          let data = selectedData[tableMeta?.rowIndex];
          return (
            <>
              <TextField
                size="small"
                value={data?.bid_amount}
                type="number"
                onWheel={(e) => e.target.blur()}
                onBlur={(e) => {
                  if (Number(e.target.value) < 0.2) {
                    const newPrice = '0.20';
                    const updatedData = [...selectedData];
                    updatedData[tableMeta?.rowIndex] = { ...data, bid_amount: newPrice };
                    setSelectedData(updatedData);
                  }
                }}
                onChange={(e) => {
                  const newPrice = Number(e.target.value == 0) ? null : Number(e.target.value);
                  const updatedData = [...selectedData];
                  updatedData[tableMeta?.rowIndex] = { ...data, bid_amount: newPrice };
                  setSelectedData(updatedData);
                }}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>
                }}
                inputProps={{
                  step: '0.01'
                }}
              />
            </>
          );
        }
      }
    },
    {
      name: 'item_id',
      label: 'Action',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return (
            <>
              <IconButton
                onClick={() => {
                  var tempData = [...selectedData];
                  tempData = tempData?.filter((item) => item?.item_id != value);
                  setSelectedData(tempData);
                }}
              >
                <CloseIcon />
              </IconButton>
            </>
          );
        }
      }
    }
  ];

  const selectedOptions = {
    filter: false,
    print: false,
    jumpToPage: false,
    download: false,
    responsive: 'standard',
    fixedHeader: true,
    fixedSelectColumn: false,
    selectToolbarPlacement: 'none',
    // tableBodyHeight: '350px',
    rowsPerPage: rowPerPage,
    pagination: true,
    viewColumns: false,
    serverSide: false,
    rowsPerPageOptions: PageValue,
    selectableRows: 'none',
    toolbar: false,
    search: false,
    onChangeRowsPerPage: (row) => {
      setRowPerPage(row);
    }
  };

  return (
    <>
      <form noValidate onSubmit={onButtonClick}>
        <Box>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item sm={12}>
              <Typography variant="h4">
                Campaign:({itemModal?.selectedCampaignName?.length})
                <Box
                  sx={{
                    overflow: 'auto',
                    height: '80px',
                    width: '100%',
                    my: 2,
                    p: 1,

                    // boxShadow: " 0.5px 1px 1px 1px rgba(0,0,0,.05)",
                    '&::-webkit-scrollbar': {
                      width: '0.1em',
                      backgroundColor: '#C5C5C5'
                    },
                    '&::-webkit-scrollbar-track': {
                      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
                    },
                    '&::-webkit-scrollbar-thumb': {
                      backgroundColor: '#7A8183',
                      outline: '1px solid slategrey'
                    }
                  }}
                >
                  {itemModal?.selectedCampaignName?.map((item, index) => {
                    return <Typography key={index}>{item}</Typography>;
                  })}
                </Box>
              </Typography>
            </Grid>
            <Grid item sm={12}>
              <CustomAutocomplete
                multiple
                label="Adgroup Name"
                id="tags-outlined"
                name="adgroup_name"
                value={selectedAdgroup || []}
                options={adgroupList || []}
                // getOptionLabel={(option) => option.name}
                onChange={(e, value) => {
                  if (value.find((option) => option.all)) {
                    const allIdsCam = adgroupList?.map((obj) => obj.id);

                    if (value.length - 1 === allIdsCam.length) {
                      return setSelectedAdgroup([]);
                    } else {
                      return setSelectedAdgroup(adgroupList);
                    }
                  }
                  setSelectedAdgroup(value);
                }}
                onOpen={() => SetIsDropdownOpenCamSt(true)}
                onClose={() => SetIsDropdownOpenCamSt(false)}
                filterSelectedOptions
                color="secondary"
                optionLabelkey="name"
                onInputChange={(event, value, reason) => {
                  if (reason !== 'reset') {
                    setSearchText({ ...SearchText, searchTextAdgroup: value });
                  }
                }}
                inputValue={IsDropdownOpenCamSt ? SearchText?.searchTextAdgroup : ''}
                selectAllCheckboxCondiion={!!(selectedAdgroup?.length == adgroupList.length)}
                placeholderCondition={selectedAdgroup?.length > 0}
                isOptionEqualToValue={(option, value) => option?.id == value?.id}
              />
              {error.adGroup && (
                <FormHelperText error sx={{ margin: '3px 14px 0px 14px' }}>
                  Please select adgroup
                </FormHelperText>
              )}
            </Grid>
            <Grid item sm={12}>
              {renderLabel('Item', true)}
              <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={value}>
                  <Box sx={{ border: 1, borderColor: 'divider', borderRadius: '12px' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <TabList
                        onChange={handleChange}
                        aria-label="lab API tabs example"
                        TabIndicatorProps={{ style: { backgroundColor: colors.secondaryMain } }}
                      >
                        <Tab label="Search" sx={{ '&.Mui-selected': { color: colors.secondaryMain } }} value="1" />
                        <Tab label="Select from" sx={{ '&.Mui-selected': { color: colors.secondaryMain } }} value="2" />
                        <Tab label="Suggested" sx={{ '&.Mui-selected': { color: colors.secondaryMain } }} value="3" />
                        <Tab label="Enter Items" sx={{ '&.Mui-selected': { color: colors.secondaryMain } }} value="4" />
                      </TabList>
                    </Box>
                    <TabPanel value="1" sx={{ padding: 0 }}>
                      <SearchTable
                        advertiserId={setSelectedData}
                        advertiser_id={itemModal?.advertiserId}
                        selectedData={selectedData}
                        setSelectedData={setSelectedData}
                        onAddAllClick={onAddAllClick}
                      />
                    </TabPanel>
                    <TabPanel value="2" sx={{ padding: 0 }}>
                      <SelectFromTable
                        setSearchableData={setSearchableData}
                        advertiserId={itemModal?.advertiserId}
                        selectedData={selectedData}
                        setSelectedData={setSelectedData}
                        onAddAllClick={onAddAllClick}
                      />
                    </TabPanel>
                    <TabPanel value="3">
                      <SuggestFromTable
                        setSearchableData={setSearchableData}
                        advertiserId={itemModal?.advertiserId}
                        selectedData={selectedData}
                        setSelectedData={setSelectedData}
                        onAddAllClick={onAddAllClick}
                      />
                    </TabPanel>
                    <TabPanel value="4" sx={{ padding: 0 }}>
                      <EnterKeywordTable
                        selectedData={selectedData}
                        setSelectedData={setSelectedData}
                        keyword={keyword}
                        setkeyword={setkeyword}
                        advertiserId={itemModal?.advertiserId}
                      />
                    </TabPanel>
                  </Box>
                </TabContext>

                <Box sx={{ mt: 5, border: 1, borderColor: 'divider', borderRadius: '12px' }}>
                  <Box
                    sx={{
                      borderBottom: 1,
                      borderColor: 'divider',
                      padding: '20px 30px 20px 30px',
                      display: 'flex',
                      justifyContent: 'space-between'
                    }}
                  >
                    <Typography variant="h5">{selectedData?.length} Item(s)Added</Typography>
                    <Typography variant="h6" sx={{ color: colors.grey500 }}>
                      Item bid is only valid for Auto Campaigns
                    </Typography>
                  </Box>

                  <MainCard>
                    <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                      <Button
                        className="Button-remove-all"
                        disabled={!selectedData?.length}
                        variant="outlined"
                        color="secondary"
                        size="small"
                        onClick={() => setSelectedData([])}
                        sx={{ marginBottom: '8px' }}
                      >
                        Remove All
                      </Button>
                    </Box>
                    <MUIDataTable
                      actions={[
                        {
                          icon: 'save'
                        }
                      ]}
                      style={{ padding: 0, marginTop: '30px !important' }}
                      data={selectedData}
                      // columns={selectedColumns}
                      columns={selectedColumns}
                      options={selectedOptions}
                      className="custom-table-scroll pnl-by-item"
                    />
                  </MainCard>
                </Box>
              </Box>

              {error.adItem && (
                <FormHelperText error sx={{ margin: '3px 14px 0px 14px' }}>
                  Please select at lease one items
                </FormHelperText>
              )}
            </Grid>
          </Grid>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', mt: 2 }}>
            <>
              <Button variant="outlined" color="secondary" disabled={loading} onClick={onClose}>
                Cancel
              </Button>
              <Button variant="contained" color="secondary" sx={{ ml: 1, color: 'white' }} type="submit" disabled={loading}>
                {loading && <CircularProgress sx={{ color: '#0000001f', mr: '10px' }} size="20px" />}
                Confirm
              </Button>
            </>
          </Box>
        </Box>
      </form>
    </>
  );
};

export default ProductAdMultipleItems;

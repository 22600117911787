import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { DeleteRule, getRulesList, ApplyRuleEngline, updateRule } from 'store/thunk/userThunk';
import { useAppSelector, useAppDispatch } from 'store';
import MainCard from 'ui-component/cards/MainCard';
import MenuItemTable from 'ui-component/menuItem/MenuItemTable';
import DeleteModalDialog from 'ui-component/DeleteModal';
import {
  Box,
  Typography,
  Tooltip,
  Button,
  FormControlLabel,
  List,
  ListItem,
  Chip,
  ListItemButton,
  ListItemText,
  Zoom,
  styled
} from '@mui/material';
import { tooltipClasses } from '@mui/material/Tooltip';
import { DeleteOutline, Visibility } from '@mui/icons-material';
import colors from 'assets/scss/_themes-vars.module.scss';
import { PageValue } from 'store/constant';
import moment from 'moment';
import { CustomSwitch } from 'ui-component/customSwitch';
import CustomTableHead from 'ui-component/customTableHead';

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
    maxHeight: '350px',
    overflow: 'auto'
  }
}));

const RuleEngine = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { rulesList, commonAdvertiser } = useAppSelector((state) => state?.userDetailsSlice);
  const [newData, setNewData] = useState();
  const [appliedSortTing, setAppliedSortTing] = useState();
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteData, setDeleteData] = useState();
  const [rowPerPage, setRowPerPage] = useState(25);
  const [page, setPage] = useState(0);
  // const [selectedArray, setSelectedArray] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  const getRulesWithPagination = (page, rowPerPage, changedColumn = '', direction = '', profileIds = null) => {
    dispatch(
      getRulesList({
        page,
        rowPerPage,
        changedColumn,
        direction,
        profileIds: profileIds?.length > 0 ? profileIds?.toString() : ''
      })
    );
  };

  const toggleDeleteModal = (data) => {
    setOpenDelete(!openDelete);
    setDeleteData(data);
    // setInlineEdit({ delete: true });
  };

  const confirmDelete = async () => {
    await dispatch(DeleteRule({ ruleIds: [deleteData] })).then((res) => {
      if (res.payload.status == 204 || res.payload.status == 200) {
        // setSelectedArray([]);
        setSelectedRows([]);
        setOpenDelete(false);
        getRulesWithPagination(
          page,
          rowPerPage,
          appliedSortTing?.changedColumnSort,
          appliedSortTing?.directionSort,
          commonAdvertiser
        );
      }
    });
  };

  useEffect(() => {
    getRulesWithPagination(page, rowPerPage, '', '', commonAdvertiser);
  }, [commonAdvertiser]);

  const RunTheRule = (id) => {
    if (id) {
      dispatch(ApplyRuleEngline({ _request: { rule_id: id } }));
    }
  };

  const handleRuleStatus = (e, id) => {
    const _request = {
      updateRequest: { is_active: e.target.checked },
      getRuleRequest: {
        page,
        rowPerPage,
        changedColumn: appliedSortTing?.changedColumnSort,
        direction: appliedSortTing?.directionSort,
        profileIds: commonAdvertiser
      }
    };
    dispatch(updateRule({ _request, RuleId: id }));
  };

  const formatString = (str) => str.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase());

  const getFormattedConditions = (conditions) => {
    if (!conditions) return null;

    const conditionItems = [];
    const type = conditions.all ? 'all' : conditions.any ? 'any' : null;

    if (!type) return null; // No valid condition type

    conditions[type].forEach((condition) => {
      let conditionText;

      if (condition.all || condition.any) {
        // Recursively get formatted rules for nested conditions
        conditionText = getFormattedConditions(condition);
      } else {
        // Create text for a simple condition using formatString
        conditionText = `${formatString(condition.name)} ${formatString(condition.operator)} ${condition.value}`;
      }

      conditionItems.push(conditionText);
    });

    return (
      <Box>
        {conditionItems.length > 0 && (
          <Typography>
            <strong>If {type === 'all' ? 'All' : 'Any'} of the following conditions match:</strong>
            <ul>
              {conditionItems.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </Typography>
        )}
      </Box>
    );
  };

  const getFormattedActions = (actions) => {
    if (!actions || actions.length === 0) return null;

    return (
      <Box>
        <Typography>
          <strong>Then the following actions will be triggered:</strong>
          <ul>
            {actions.map((action, index) => (
              <li key={index}>{formatString(action.name)}</li>
            ))}
          </ul>
        </Typography>
      </Box>
    );
  };

  const getFormattedRules = (ruleData) => {
    return (
      <Box p={2}>
        <Typography variant="h4" mb={1} color="primary">
          Conditions:
        </Typography>
        {getFormattedConditions(ruleData?.conditions)}
        <Typography variant="h4" mb={1} color="primary">
          Actions:
        </Typography>
        {getFormattedActions(ruleData?.actions)}
      </Box>
    );
  };

  const getCampaignsNamesList = (campaigns) => {
    // value[0]?.name;
    return (
      <List>
        {campaigns?.map((campaign, key) => (
          <ListItem key={key} disablePadding>
            <ListItemButton>
              <ListItemText primary={campaign?.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    );
  };

  const columns = [
    {
      name: 'name',
      label: 'Rule name',
      options: {
        filter: true,
        sort: false,
        viewColumns: false,
        customHeadLabelRender: () => {
          return CustomTableHead({
            columnLabel_1: 'Rule Name'
          });
        },
        customBodyRender: (value, rowData) => {
          const values = rulesList?.results?.[rowData?.rowIndex];

          return (
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography>{value?.length > 200 ? `${value.substring(0, 35)}...` : value}</Typography>

              <LightTooltip color="primary" title={getFormattedRules(values?.rule_json)} placement="right">
                <Visibility className="cursor-pointer" />
              </LightTooltip>
            </Box>
          );
        },

        setCellProps: () => ({
          className: 'clr3',
          style: {
            whiteSpace: 'nowrap',
            position: 'sticky',
            left: 40,
            minWidth: '380px',
            background: 'white',
            zIndex: 100
          }
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: 'nowrap',
            position: 'sticky',
            left: 40,
            minWidth: '380px',
            // background: 'white',
            zIndex: 101
          },
          sx: {
            '& button': { padding: '8px !important' },
            '& button:hover': { backgroundColor: 'transparent' }
          }
        })
      }
    },
    {
      name: 'module_name',
      label: 'Module name',
      options: {
        filter: true,
        sort: false,
        setCellProps: () => ({
          className: 'clr3'
        }),
        setCellHeaderProps: () => ({
          sx: {
            '& button': { padding: '8px !important' },
            '& button:hover': { backgroundColor: 'transparent' }
          }
        }),
        customHeadLabelRender: () => {
          return CustomTableHead({
            columnLabel_1: 'Module Name'
          });
        },
        customBodyRender: (value) => {
          return value || '-';
        }
      }
    },
    {
      name: 'object_ids',
      label: 'Campaign name',
      options: {
        filter: true,
        sort: false,
        setCellProps: () => ({
          className: 'clr3'
        }),
        setCellHeaderProps: () => ({
          sx: {
            '& button': { padding: '8px !important' },
            '& button:hover': { backgroundColor: 'transparent' }
          }
        }),
        customHeadLabelRender: () => {
          return CustomTableHead({
            columnLabel_1: 'Campaign Name'
          });
        },
        customBodyRender: (value) => {
          return value?.length > 0 ? (
            <Box display="flex" alignItems="center">
              {value?.length > 0 && (
                <LightTooltip
                  color="primary"
                  TransitionComponent={Zoom}
                  title={getCampaignsNamesList(value)}
                  placement="right-start"
                >
                  <Chip className="cursor-pointer" label={value?.length} color="primary" sx={{ height: '24px' }} />
                </LightTooltip>
              )}
              <Typography ml={2}>{value[0]?.name}</Typography>
            </Box>
          ) : (
            '-'
          );
        }
      }
    },
    {
      name: 'last_executed',
      label: 'Last Executed',
      options: {
        filter: true,
        sort: false,
        setCellProps: () => ({
          className: 'clr3'
        }),
        setCellHeaderProps: () => ({
          sx: {
            '& button': { padding: '8px !important' },
            '& button:hover': { backgroundColor: 'transparent' }
          }
        }),
        customHeadLabelRender: () => {
          return CustomTableHead({
            columnLabel_1: 'Last Executed'
          });
        },
        customBodyRender: (value) => {
          return value ? moment(value).format('yyyy-MM-DD HH:mm:ss') : '';
        }
      }
    },
    {
      name: 'created_at',
      label: 'Create Date',
      options: {
        filter: true,
        sort: false,
        setCellProps: () => ({
          className: 'clr3'
        }),
        setCellHeaderProps: () => ({
          sx: {
            '& button': { padding: '8px !important' },
            '& button:hover': { backgroundColor: 'transparent' }
          }
        }),
        customHeadLabelRender: () => {
          return CustomTableHead({
            columnLabel_1: 'Create Date'
          });
        },
        customBodyRender: (value) => {
          return value ? moment(value).format('yyyy-MM-DD HH:mm:ss') : '';
        }
      }
    },
    {
      name: 'description',
      label: 'Description',
      options: {
        filter: true,
        sort: false,
        setCellProps: () => ({
          className: 'clr3'
        }),
        setCellHeaderProps: () => ({
          sx: {
            '& button': { padding: '8px !important' },
            '& button:hover': { backgroundColor: 'transparent' }
          }
        }),
        customHeadLabelRender: () => {
          return CustomTableHead({
            columnLabel_1: 'Description'
          });
        },
        customBodyRender: (value) => {
          return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <>
                {value?.length > 35 ? (
                  <LightTooltip
                    placement="top"
                    sx={{ fontSize: '10px' }}
                    title={
                      <Typography variant="body1" sx={{ fontSize: 16 }}>
                        {value}
                      </Typography>
                    }
                    arrow
                  >
                    {value.substring(0, 35)}...
                  </LightTooltip>
                ) : (
                  value
                )}
              </>
            </Box>
          );
        }
      }
    },
    {
      name: 'is_active',
      label: 'Status',
      options: {
        filter: true,
        sort: false,
        setCellProps: () => ({
          className: 'clr3'
        }),
        setCellHeaderProps: () => ({
          sx: {
            '& button': { padding: '8px !important' },
            '& button:hover': { backgroundColor: 'transparent' }
          }
        }),
        customHeadLabelRender: () => {
          return CustomTableHead({
            columnLabel_1: 'Status'
          });
        },
        customBodyRender: (value, rowData) => {
          const values = rulesList?.results?.[rowData?.rowIndex];

          return (
            <FormControlLabel
              control={
                <CustomSwitch
                  sx={{ marginRight: '6px' }}
                  color="secondary"
                  checked={values?.is_active}
                  onChange={(e) => handleRuleStatus(e, values.id)}
                />
              }
              labelPlacement="end"
            />
            // <Typography>
            //   <Box sx={{ display: 'flex', alignItems: 'center' }}>
            //     <Circle sx={{ fontSize: 'x-small', color: value ? '#28c76f' : '#bababb' }} />
            //     &nbsp;
            //     {value ? 'Active' : 'Inactive'}
            //   </Box>
            // </Typography>
          );
        }
      }
    },
    {
      name: 'id',
      label: 'Action',
      options: {
        filter: true,
        sort: false,
        setCellProps: () => ({
          className: 'clr3'
        }),
        setCellHeaderProps: () => ({
          sx: {
            '& button': { padding: '8px !important' },
            '& button:hover': { backgroundColor: 'transparent' }
          }
        }),
        customHeadLabelRender: () => {
          return CustomTableHead({
            columnLabel_1: 'Action'
          });
        },
        customBodyRender: (value) => {
          return (
            <Box display="flex" alignItems="center" gap={0.5}>
              <Tooltip title="Delete" arrow>
                <DeleteOutline sx={{ color: colors?.errorDark, cursor: 'pointer' }} onClick={() => toggleDeleteModal(value)} />
              </Tooltip>
              <Button variant="text" onClick={() => RunTheRule(value)}>
                Run Rule
              </Button>
            </Box>
          );
        }
      }
    }
  ];

  useEffect(() => {
    if (rulesList?.results) {
      setNewData(rulesList?.results);
    }
  }, [rulesList]);

  const initialColumnOrder = columns.map((_, index) => index);
  const [columnOrder, setColumnOrder] = useState(initialColumnOrder);

  const options = {
    search: false,
    filter: false,
    print: false,
    jumpToPage: false,
    responsive: 'standard',
    fixedHeader: true,
    filterType: 'checkbox',
    fixedSelectColumn: true,
    selectToolbarPlacement: 'none',
    tableBodyHeight: '550px',
    download: false,
    serverSide: true,
    page: page,
    count: rulesList?.count || 0,
    viewColumns: false,
    selectableRowsHeader: true,
    selectableRowsHideCheckboxes: false,
    rowsPerPageOptions: PageValue,
    rowsPerPage: rowPerPage,
    pagination: true,
    rowsSelected: selectedRows,
    draggableColumns: {
      enabled: true
    },
    columnOrder: columnOrder,
    onColumnOrderChange: (newColumnOrder) => {
      if (newColumnOrder[0] !== 0) {
        setColumnOrder([...columnOrder]);
      } else {
        setColumnOrder(newColumnOrder);
      }
    },
    customToolbar: () => (
      <Button
        variant="contained"
        onClick={() => {
          navigate('/ppc-rules/add-rule');
        }}
      >
        Add Rule
      </Button>
    ),
    // onRowSelectionChange: (rowsSelectedData, allRows, rowsSelected) => {
    //   const selectedIds = rowsSelected.map((selectedIndex) => rulesList?.results?.[selectedIndex]?.id);
    //   //   const selectedObj = rowsSelected.map((selectedIndex) => rulesList?.results?.[selectedIndex]);
    //   //   setSelectedObj(selectedObj);
    //   setSelectedArray(selectedIds);
    //   setSelectedRows(allRows.map((row) => row.dataIndex));
    // },
    onChangeRowsPerPage: (row) => {
      setRowPerPage(row);
      getRulesWithPagination(page, row, appliedSortTing?.changedColumnSort, appliedSortTing?.directionSort, commonAdvertiser);
    },
    onChangePage: (page) => {
      setPage(page);
      getRulesWithPagination(
        page,
        rowPerPage,
        appliedSortTing?.changedColumnSort,
        appliedSortTing?.directionSort,
        commonAdvertiser
      );
    },

    onColumnSortChange: (changedColumn, direction) => {
      setPage(0);
      const payload = {
        changedColumnSort: changedColumn,
        directionSort: direction
      };
      setAppliedSortTing(payload);
      getRulesWithPagination(page, rowPerPage, changedColumn, direction);
    }
  };

  return (
    <MainCard title="PPC Rules">
      <MenuItemTable className="custom-budget-table" data={newData} columns={columns} options={options} />
      <DeleteModalDialog
        item="Rule"
        cancelCallback={() => setOpenDelete(!openDelete)}
        deleteCallback={confirmDelete}
        open={openDelete}
      />
    </MainCard>
  );
};

export default RuleEngine;

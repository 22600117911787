import { Box, IconButton } from '@mui/material';
import { Info } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const CustomWidthTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
    fontSize: 14
  }
});
const CustomTableHead = ({
  columnLabel_1,
  columnLabel_2 = '',
  isLabelAlignRight = false,
  isParentLabelAlignRight = false,
  isLabelTooltip = false,
  tooltipInfo = ''
}) => {
  return (
    <Box display="flex" alignItems="center" justifyContent={isParentLabelAlignRight ? 'flex-end' : 'flex-start'} gap={'5px'}>
      <Box
        display="flex"
        alignItems={isLabelAlignRight ? 'flex-end' : 'flex-start'}
        flexDirection="column"
        justifyContent="flex-start"
      >
        <Box className="clr3 fw700 ffInter">{columnLabel_1}</Box>
        {columnLabel_2 && <Box className="clr3 fw700 ffInter">{columnLabel_2}</Box>}
      </Box>
      {isLabelTooltip && (
        <Box>
          <CustomWidthTooltip arrow title={<Box dangerouslySetInnerHTML={{ __html: tooltipInfo }} />}>
            <IconButton size="small" style={{ marginLeft: 4 }}>
              <Info fontSize="inherit" />
            </IconButton>
          </CustomWidthTooltip>
        </Box>
      )}
    </Box>
  );
};

export default CustomTableHead;

import * as React from 'react';
import { Box, Stepper, StepLabel, Step, Button, Typography, StepContent } from '@mui/material';
import ConditionsActionsDemo from './business-rule';
import ModuleDetails from './module-details';
import { useState, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { CreateRule } from 'store/thunk/userThunk';
import { useAppDispatch, useAppSelector } from 'store';

const steps = ['Rule Details', 'Create Your rule'];

export default function AddRule() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { commonAdvertiser, ruleExists } = useAppSelector((state) => state?.userDetailsSlice);

  const [activeStep, setActiveStep] = useState(0);
  const [paramData, setParams] = useState({
    ruleName: '',
    description: '',
    moduleName: 'Campaign',
    profile: null,
    selectedCampaign: null,
    frequency: 60
  });
  
  const [allowNext, setAllowNext] = useState(null);
  const [finalObject, setFinalObject] = useState({});

  const handleSave = () => {
    const requestParmas = {
      rule_name: paramData?.ruleName,
      description: paramData?.description,
      module_name: paramData?.moduleName,
      frequency: parseInt(paramData?.frequency),
      profile_ids: commonAdvertiser,
      object_ids: paramData?.selectedCampaign?.length ? paramData?.selectedCampaign.map((campaign) => campaign?.id) : [],
      rule_json: finalObject
    };

    dispatch(CreateRule({ _request: requestParmas, navigate }));
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return <ModuleDetails paramData={paramData} setParams={setParams} showError={ruleExists} />;
      case 1:
        return <ConditionsActionsDemo paramData={paramData} setFinalObject={setFinalObject} finalObject={finalObject} />;
      default:
        return <div>Not Found</div>;
    }
  };

  useMemo(() => {
    setAllowNext(!ruleExists);
  }, [ruleExists]);

  return (
    <Box sx={{ width: '100%' }}>
      <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
        <Box>
          {paramData?.selectedCampaign?.length > 0 && (
            <Box display="flex" gap="10px">
              <Typography variant="h4">Campaigns Selected: </Typography>{' '}
              <Typography variant="h5">{paramData?.selectedCampaign?.length}</Typography>
            </Box>
          )}
        </Box>
        <Button
          variant="contained"
          onClick={() => {
            navigate('/ppc-rules');
          }}
          sx={{ ml: 2 }}
        >
          Back to Rule List
        </Button>
      </Box>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel
              optional={
                index === steps.length - 1 ? (
                  <Typography variant="caption">Create conditions and actions, then preview them.</Typography>
                ) : (
                  <Typography variant="caption">Add Rule and Module details</Typography>
                )
              }
            >
              {step}
            </StepLabel>
            <StepContent>
              {/* <Typography>{step.description}</Typography> */}
              <Box>{renderStepContent(activeStep)}</Box>
              <Box sx={{ mb: 2 }}>
                {activeStep === steps.length - 1 ? (
                  <Button
                    sx={{ mt: 1, mr: 1 }}
                    variant="contained"
                    onClick={handleSave}
                    disabled={!paramData?.selectedCampaign || !Object.keys(finalObject).length > 0}
                  >
                    Save
                  </Button>
                ) : (
                  <Button
                    sx={{ mt: 1, mr: 1 }}
                    variant="contained"
                    onClick={handleNext}
                    disabled={paramData?.selectedCampaign && paramData?.ruleName?.length > 0 && allowNext ? false : true}
                  >
                    Next
                  </Button>
                )}
                <Button disabled={index === 0} onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                  Back
                </Button>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}

import React from 'react';
import { Button, Typography, Box, IconButton, Grid, Link } from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import BudgetManagerTable from 'ui-component/budget-manager/MUITable';
import SearchIcon from '@mui/icons-material/Search';
import editIcon from 'assets/images/dashna/edit.svg';
import { DeleteOutline, InfoOutlined, CheckCircleOutlined } from '@mui/icons-material';

import DeleteModalDialog from 'ui-component/DeleteModal';
import { useState } from 'react';
import { useEffect } from 'react';
import { formateValue } from 'helper/commonHelper';
import moment from 'moment';
import { AllStoresData, GetAdvertisersStoreData, GetSelectedStoreData, deleteStore } from 'store/thunk/userThunk';
import { useAppSelector } from 'store';
import { useAppDispatch } from 'store';
import Tooltip from '@mui/material/Tooltip';
// import { useTheme } from '@mui/material/styles';
import colors from 'assets/scss/_themes-vars.module.scss';
import CustomAutocomplete from 'ui-component/multi-select/CustomAutocomplete';
import { PageValue, Seller_Type, tenantKanhasoftID } from 'store/constant';
import CreateStore from '../pages/authentication/emailVerification/modals/createStore';
import ConnectWalmartSeller from '../pages/authentication/emailVerification/modals/connectWalmartSeller';
import { setSelectedStoreData } from 'store/slices/userDetailsSlice';
import { Add } from '@mui/icons-material';
import ShareIcon from '../../assets/images/dashna/shareIcon.svg';
import { notificationFail, notificationSuccess } from 'store/slices/notificationSlice';
import CustomTableHead from 'ui-component/customTableHead';
import { CLIENT_ID_3P, CLIENT_ID_1P, DEV_APP_URL, LIVE_APP_URL } from 'store/constant';
import { generateRandomString } from 'helper/commonHelper';
import { encryptText } from 'helper/commonHelper';

const StoreList = () => {
  const [openDelete, setOpenDelete] = useState(false);

  const [isOpenModalAddStore, setIsOpenModalAddStore] = useState(false); //Add Store code
  const [isOpenModalEditStore, setIsOpenModalEditStore] = useState(false); //Edit Store code
  const [deleteData, setDeleteData] = useState();
  const [newData, setNewData] = useState();
  const { StorData, AllStorDataAdvertiser } = useAppSelector((state) => state?.userDetailsSlice);
  const [userDetails, setUserDetails] = useState([]);

  useEffect(() => {
    let userdata = window.localStorage.getItem('user_data');
    setUserDetails(JSON.parse(userdata));
  }, []);

  const dispatch = useAppDispatch();
  const [isSelectAll, setIsSelectAll] = useState({
    partner_name: [],
    report_type: []
  });

  const [rowPerPage, setRowPerPage] = useState(25);
  const [page, setPage] = useState(0);
  const [
    changedColumn
    // setChangedColumn
  ] = useState('');
  const [
    direction
    // setDirection
  ] = useState('');

  //Permission checking
  const [filterValue, setFilterValue] = useState({
    partner_name: [],
    partner_display_name: [],
    partner_store_id: [],
    nick_name: [],
    seller_type: []
  });
  const [tableFilter, setTableFilter] = useState({
    partner_name: '',
    partner_display_name: '',
    partner_store_id: '',
    nick_name: '',
    seller_type: ''
  });
  const [searchText, setSearchText] = useState({
    partner_name: null,
    partner_display_name: null,
    partner_store_id: null,
    nick_name: null,
    seller_type: null
  });
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdownOpenDisplay, setIsDropdownOpenDisplay] = useState(false);
  const [isDropdownOpenNick, setIsDropdownOpenNick] = useState(false);
  const [isDropdownOpenType, setIsDropdownOpenType] = useState(false);
  const [isDropdownOpenWalmart, setIsDropdownOpenWalmart] = useState(false);
  const [fixDisconnectedStore, setFixDisconnectedStore] = useState(false);
  // const theme = useTheme();
  // const ColumnColor = theme.palette.grey[700];
  // const orange = colors.orangeMain;
  // const green = colors.greenFill;

  const tooltipSellerType = `
  <div>
    The ID that Walmart uses to identify your store. <br/>
    For 1P Suppliers that will be their Supplier Id and <br/> for 3P Sellers that will be their Seller ID.
  </div>
`;

  const handleDisconnectedStore = (storeId) => {
    toggleEditStore(storeId);
    setFixDisconnectedStore(true);
  };

  const handleSearchFilter = () => {
    getStoreListWithPagination(page, rowPerPage, changedColumn, direction, tableFilter);
  };

  const handleAutoCompleteOnChange = (newValue, filterKey) => {
    let paramKey = '';
    if (filterKey === 'partner_name') {
      paramKey = 'partner_name';
    }
    if (filterKey === 'partner_display_name') {
      paramKey = 'partner_display_name';
    }

    if (filterKey === 'nick_name') {
      paramKey = 'nick_name';
    }

    if (filterKey === 'seller_type') {
      paramKey = 'seller_type';
    }

    if (filterKey === 'partner_store_id') {
      paramKey = 'partner_store_id';
    }
    const idArray = newValue?.map((item) => item?.[paramKey]);
    const uniqueIdArray = [...new Set(idArray)];
    let idString = uniqueIdArray?.join(',') || '';

    let baseArray = [];
    let tableStateFilterKey = '';

    if (filterKey === 'partner_name') {
      baseArray = [...AllStorDataAdvertiser];
      tableStateFilterKey = 'partner_name';
    }

    if (filterKey === 'partner_store_id') {
      baseArray = [...AllStorDataAdvertiser];
      tableStateFilterKey = 'partner_store_id';
    }
    if (filterKey === 'partner_display_name') {
      baseArray = [...AllStorDataAdvertiser];
      tableStateFilterKey = 'partner_display_name';
    }

    if (filterKey === 'seller_type') {
      baseArray = [...Seller_Type];
      tableStateFilterKey = 'seller_type';
    }

    if (filterKey === 'nick_name') {
      baseArray = [...AllStorDataAdvertiser];
      tableStateFilterKey = 'nick_name';
    }

    //Check if Select All checkbox is slected
    if (newValue.find((option) => option.all)) {
      const searchTerm = searchText?.[filterKey]?.toLowerCase() || ''; //Change as per selected filter
      const filteredCampaigns = searchTerm
        ? baseArray.filter((item) => item?.[paramKey]?.toLowerCase().includes(searchTerm))
        : baseArray;
      const allIdsCam = filteredCampaigns.map((obj) => obj.id);

      setIsSelectAll((prevState) => ({
        ...prevState,
        [filterKey]: filteredCampaigns
      }));
      idString =
        newValue.length === 1 || newValue.length <= filteredCampaigns.length
          ? [...new Set(filteredCampaigns.map((item) => item?.[paramKey]))].join(',')
          : '';
      setTableFilter((prevState) => ({
        ...prevState,
        ...(filterKey === 'campaign_type' && { targeting: '' }),
        [tableStateFilterKey]: idString
      }));
      setFilterValue((prevState) => ({
        ...prevState,
        [tableStateFilterKey]: newValue.length - 1 === allIdsCam.length ? [] : filteredCampaigns
      }));
    } else {
      setTableFilter((prevState) => ({
        ...prevState,
        ...(filterKey === 'campaign_type' && { targeting: targetString }),
        [tableStateFilterKey]: idString
      }));
      setFilterValue((prevState) => ({
        ...prevState,
        [tableStateFilterKey]: newValue
      }));
    }
  };

  //For setting Select All checkbox for Filter Campaign, Campaign Status and Campaign Type Dropdown
  const handleAutoCompleteSelectAll = (filterKey) => {
    let baseArray = [];
    let tableStateFilterKey = '';

    if (filterKey === 'partner_name') {
      baseArray = [...AllStorDataAdvertiser];
      tableStateFilterKey = 'partner_name';
    }

    if (filterKey === 'partner_display_name') {
      baseArray = [...AllStorDataAdvertiser];
      tableStateFilterKey = 'partner_display_name';
    }

    if (filterKey === 'nick_name') {
      baseArray = [...AllStorDataAdvertiser];
      tableStateFilterKey = 'nick_name';
    }

    if (filterKey === 'seller_type') {
      baseArray = [...Seller_Type];
      tableStateFilterKey = 'seller_type';
    }

    if (filterKey === 'partner_store_id') {
      baseArray = [...AllStorDataAdvertiser];
      tableStateFilterKey = 'partner_store_id';
    }

    const status =
      filterValue?.[tableStateFilterKey]?.length && isSelectAll?.[filterKey]?.length
        ? !!(filterValue?.[tableStateFilterKey]?.length == baseArray?.length) ||
          !!(filterValue?.[tableStateFilterKey]?.length == isSelectAll?.[filterKey]?.length)
        : false;

    return status;
  };

  const columns = [
    {
      name: 'partner_name',
      label: 'Store Name',
      options: {
        filter: true,
        sort: false,
        sticky: true,
        leftTotal: 0,
        // viewColumns: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: 'nowrap',
            position: 'sticky',
            left: 0,
            minWidth: '380px',
            zIndex: 101,
            color: colors?.primaryTextColor
          },
          sx: {
            '& button': { padding: '8px !important' },
            '& button:hover': { backgroundColor: 'transparent' }
          }
        }),
        customHeadLabelRender: () => {
          return CustomTableHead({
            columnLabel_1: 'Store Name'
          });
        },
        setCellProps: () => ({
          className: 'clr3',
          style: {
            whiteSpace: 'nowrap',
            position: 'sticky',
            left: 0,
            minWidth: '380px',
            // background: 'white',
            zIndex: 100
          }
        })
      }
    },
    {
      name: 'nick_name',
      label: 'Nickname',
      options: {
        filter: true,
        sort: false,
        setCellProps: () => ({
          className: 'clr3'
        }),
        setCellHeaderProps: () => ({
          sx: {
            '& button': { padding: '8px !important' },
            '& button:hover': { backgroundColor: 'transparent' }
          }
        }),
        customHeadLabelRender: () => {
          return CustomTableHead({
            columnLabel_1: 'Nickname'
          });
        },
        customBodyRender: (value) => {
          return (
            <Box sx={{ display: 'flex', justifyContent: 'flex-start' }} gap={2}>
              {value}
            </Box>
          );
        }
      }
    },
    {
      name: 'partner_display_name',
      label: 'Display Name',
      options: {
        filter: true,
        sort: false,
        setCellProps: () => ({
          className: 'clr3'
        }),
        setCellHeaderProps: () => ({
          sx: {
            '& button': { padding: '8px !important' },
            '& button:hover': { backgroundColor: 'transparent' }
          }
        }),
        customHeadLabelRender: () => {
          return CustomTableHead({
            columnLabel_1: 'Display Name'
          });
        },
        customBodyRender: (value) => {
          return (
            <Box sx={{ display: 'flex', justifyContent: 'left' }} gap={2}>
              {value}
            </Box>
          );
        }
      }
    },
    {
      name: 'partner_store_id',
      label: 'Walmart ID',
      options: {
        filter: true,
        sort: false,
        setCellProps: () => ({
          className: 'clr3'
        }),
        setCellHeaderProps: () => ({
          sx: {
            '& button': { padding: '8px !important' },
            '& button:hover': { backgroundColor: 'transparent' }
          }
        }),
        customHeadLabelRender: () => {
          return CustomTableHead({
            columnLabel_1: 'Walmart ID',
            isLabelTooltip: true,
            tooltipInfo: tooltipSellerType
          });
        },
        customBodyRender: (value, rowData) => {
          const storeData = StorData?.results[rowData?.rowIndex];
          const walmartID = storeData?.seller_type === '1p' ? storeData?.supplier_id : storeData?.seller_id;
          return <Box sx={{ display: 'flex', justifyContent: 'left' }}>{walmartID}</Box>;
        }
      }
    },
    {
      name: 'seller_type',
      label: 'Type',
      options: {
        filter: true,
        sort: false,
        setCellProps: () => ({
          className: 'clr3'
        }),
        setCellHeaderProps: () => ({
          sx: {
            '& button': { padding: '8px !important' },
            '& button:hover': { backgroundColor: 'transparent' }
          }
        }),
        customHeadLabelRender: () => {
          return CustomTableHead({
            columnLabel_1: 'Type'
          });
        },
        customBodyRender: (value) => {
          return (
            <Box sx={{ display: 'flex', justifyContent: 'left' }} gap={2}>
              {value.toUpperCase()}
            </Box>
          );
        }
      }
    },
    {
      name: 'connection_status',
      label: 'Product Sync',
      options: {
        filter: true,
        sort: false,
        setCellProps: () => ({
          className: 'clr3'
        }),
        setCellHeaderProps: () => ({
          sx: {
            '& button': { padding: '8px !important' },
            '& button:hover': { backgroundColor: 'transparent' }
          }
        }),
        customHeadLabelRender: () => {
          return CustomTableHead({
            columnLabel_1: 'Product Sync'
          });
        },
        customBodyRender: (value, rowData) => {
          const storeData = StorData?.results[rowData?.rowIndex];
          const storeId = storeData?.id;
          const lastSyncDate = storeData?.last_sync_datetime;
          return (
            <Box>
              <Box display="flex" gap="4px" alignItems="center">
                {value === 'disconnected' ? <InfoOutlined color="error" /> : <CheckCircleOutlined color="success" />}
                <Typography className="capitalize-text">{value}</Typography>
              </Box>
              {lastSyncDate && (
                <Box display="flex" gap={1}>
                  <Typography> Last Sync: </Typography>
                  <Typography>{moment(lastSyncDate).format('MMM D, YYYY HH:mm')}</Typography>
                </Box>
              )}
              {value === 'disconnected' && (
                <Typography className="error-text cursor-pointer" onClick={() => handleDisconnectedStore(storeId)}>
                  Click here to fix
                </Typography>
              )}
            </Box>
          );
        }
      }
    },
    {
      name: 'advertiser_connection_status',
      label: 'Advertising Sync',
      options: {
        filter: true,
        sort: false,
        setCellProps: () => ({
          className: 'clr3'
        }),
        setCellHeaderProps: () => ({
          sx: {
            '& button': { padding: '8px !important' },
            '& button:hover': { backgroundColor: 'transparent' }
          }
        }),
        customHeadLabelRender: () => {
          return CustomTableHead({
            columnLabel_1: 'Advertising Sync'
          });
        },
        customBodyRender: (value, rowData) => {
          const storeData = StorData?.results[rowData?.rowIndex];
          const storeId = storeData?.id;
          const advertiserSyncDate = storeData?.advertiser_sync_date;

          return (
            <Box>
              <Box display="flex" gap="4px" alignItems="center">
                {value === 'disconnected' ? <InfoOutlined color="error" /> : <CheckCircleOutlined color="success" />}
                <Link target="_blank" underline="none" className="capitalize-text" href={`/advertiser/${storeId}/`}>
                  {value}
                </Link>
              </Box>
              {advertiserSyncDate && (
                <Box display="flex" gap={1}>
                  <Typography> Last Sync: </Typography>
                  <Typography>{moment(advertiserSyncDate).format('MMM D, YYYY HH:mm')}</Typography>
                </Box>
              )}
              {value === 'disconnected' && (
                <Typography className="error-text cursor-pointer" onClick={() => handleDisconnectedStore(storeId)}>
                  Click here to fix
                </Typography>
              )}
            </Box>
          );
        }
      }
    },
    {
      name: 'created_at',
      label: 'Create Date',
      options: {
        filter: true,
        sort: false,
        display: true,
        setCellProps: () => ({
          className: 'clr3'
        }),
        setCellHeaderProps: () => ({
          sx: {
            '& button': { padding: '8px !important' },
            '& button:hover': { backgroundColor: 'transparent' }
          }
        }),
        customHeadLabelRender: () => {
          return CustomTableHead({
            columnLabel_1: 'Create Date'
          });
        },
        customBodyRender: (value) => {
          return <Box>{moment(value).format('MM/DD/YYYY')}</Box>;
        }
      }
    },
    ...(tenantKanhasoftID.includes(userDetails?.tenant_id)
      ? [
          {
            name: 'oAuth',
            label: 'Update OAuth',
            options: {
              filter: true,
              sort: false,
              setCellProps: () => ({
                className: 'clr3'
              }),
              setCellHeaderProps: () => ({
                sx: {
                  '& button': { padding: '8px !important' },
                  '& button:hover': { backgroundColor: 'transparent' }
                }
              }),
              customHeadLabelRender: () => {
                return CustomTableHead({
                  columnLabel_1: 'Update OAuth'
                });
              },
              customBodyRender: (value, rowData) => {
                const data = StorData?.results[rowData?.rowIndex];
                const OauthLink = getOauthLink(data);
                return (
                  <Box display="flex" alignItems="center" justifyContent={'center'}>
                    <Box className="cursor-pointer" onClick={() => copyToClipboard(`${OauthLink}`)}>
                      <Tooltip title="Copy Update OAuth Link" arrow>
                        <img src={ShareIcon} alt="share icon" width={'30px'} height={'30px'} />
                      </Tooltip>
                    </Box>
                  </Box>
                );
              }
            }
          }
        ]
      : []),
    {
      name: 'id',
      label: 'Action',
      options: {
        filter: true,
        sort: false,
        setCellProps: () => ({
          className: 'clr3'
        }),
        setCellHeaderProps: () => ({
          sx: {
            '& button': { padding: '8px !important' },
            '& button:hover': { backgroundColor: 'transparent' }
          }
        }),
        customHeadLabelRender: () => {
          return CustomTableHead({
            columnLabel_1: 'Action'
          });
        },
        customBodyRender: (value) => {
          const newValue = value?.replace(/,/g, '');
          return (
            <Box display="flex" alignItems="center">
              <Tooltip title="Edit Store">
                <IconButton onClick={() => toggleEditStore(newValue)}>
                  <img src={editIcon} alt="Icon" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete Store">
                <DeleteOutline sx={{ cursor: 'pointer', color: colors?.errorDark }} onClick={() => toggleDeleteModal(newValue)} />
              </Tooltip>
            </Box>
          );
        }
      }
    }
  ];

  //Create Walmart Login link based on 1p or 3p store
  const getOauthLink = (data) => {
    const sellerType = data?.seller_type;
    const responseType = 'code';
    const clientId = sellerType === '3p' ? CLIENT_ID_3P : CLIENT_ID_1P;
    const clientType = sellerType === '3p' ? 'seller' : 'supplier';
    const nonce = generateRandomString(8);
    // const state = generateRandomString(10);
    // const getWalmartStoreId = sellerType === '3p' ? data?.seller_id : data?.supplier_id;
    const getPrimaryStoreId = data?.id;
    //Create Dynamic Walmart state
    const dynamicState = `${userDetails?.tenant_id}_${getPrimaryStoreId}`;
    const state = encryptText(dynamicState);
    const hostURL =
      window.location.hostname.includes('dev') || window.location.hostname.includes('localhost') ? DEV_APP_URL : LIVE_APP_URL;
    const redirectUri = `${hostURL}/resource/${sellerType}/applanding`;
    const loginURL = `https://login.account.wal-mart.com/authorize?responseType=${responseType}&clientId=${clientId}&clientType=${clientType}&redirectUri=${redirectUri}&state=${state}&nonce=${nonce}`;
    return loginURL;
  };

  const getStoreListWithPagination = (page, rowPerPage, changedColumn = '', direction = '', tableFilter = '') => {
    {
      dispatch(
        GetAdvertisersStoreData({
          page,
          rowPerPage,
          changedColumn,
          direction,
          tableFilter
        })
      );
    }
  };

  useEffect(() => {
    if (!isOpenModalAddStore || !isOpenModalEditStore) {
      dispatch(setSelectedStoreData({}));
    }
  }, [isOpenModalAddStore, isOpenModalEditStore]);

  useEffect(() => {
    getStoreListWithPagination(page, rowPerPage, '', '');
    dispatch(AllStoresData());
  }, []);

  const formateData = (keyArr) => {
    const formattedData = {};
    keyArr.forEach(([key, value]) => {
      formattedData[key] = formateValue(key, value || 0);
    });

    return formattedData;
  };

  useEffect(() => {
    const finalData = StorData?.results?.map((val) => {
      return {
        ...formateData(Object.entries(val)),
        details: {
          daily_goal: val?.daily_goal,
          yesterday_spend: val?.yesterday_spend
        }
      };
    });
    setNewData(finalData);
  }, [StorData]);

  //Add New Store
  const handleAddStore = () => {
    setIsOpenModalAddStore(true);
  };

  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        dispatch(notificationSuccess('Link copied to clipboard'));
      })
      .catch((err) => {
        console.error('Failed to copy text: ', err);
        dispatch(notificationFail('Error in copying link to clipboard'));
      });
  };

  const initialColumnOrder = columns.map((_, index) => index);
  const [columnOrder, setColumnOrder] = useState(initialColumnOrder);

  const options = {
    search: false,
    filter: false,
    print: false,
    page: page,
    responsive: 'standard',
    jumpToPage: false,
    fixedHeader: true,
    fixedSelectColumn: true,
    selectToolbarPlacement: 'none',
    tableBodyHeight: '550px',
    download: false,
    count: StorData?.count || 0,
    viewColumns: false,
    selectableRows: false,
    rowsPerPageOptions: PageValue,
    rowsPerPage: rowPerPage,
    pagination: true,
    serverSide: true,
    draggableColumns: {
      enabled: true
    },
    columnOrder: columnOrder,
    onColumnOrderChange: (newColumnOrder) => {
      if (newColumnOrder[0] !== 0) {
        setColumnOrder([...columnOrder]);
      } else {
        setColumnOrder(newColumnOrder);
      }
    },
    customToolbar: () => (
      <>
        <Box display={'flex'} flexDirection={'row'}>
          <Button startIcon={<Add />} variant="contained" onClick={() => handleAddStore()}>
            Add Store
          </Button>
          {tenantKanhasoftID.includes(userDetails?.tenant_id) && (
            <Box
              sx={{ cursor: 'pointer' }}
              onClick={() => copyToClipboard(`${window.location.origin}/external-store-creation/${StorData?.secret_key}`)}
              display={'flex'}
              alignItems={'center'}
              ml={1}
            >
              <Tooltip title="Copy External Store Creation Link" arrow>
                <img src={ShareIcon} alt="share icon" width={'30px'} height={'30px'} />
              </Tooltip>
            </Box>
          )}
        </Box>
      </>
    ),
    onChangeRowsPerPage: (row) => {
      setRowPerPage(row);
      setPage(0);
      getStoreListWithPagination(0, row, changedColumn, direction, tableFilter);
    },
    onChangePage: (page) => {
      setPage(page);
      getStoreListWithPagination(page, rowPerPage, changedColumn, direction, tableFilter);
    }
  };

  useEffect(() => {
    if (!Array.isArray(columns) || !columns?.length) {
      return;
    }
    if (columns?.length !== columnOrder?.length) {
      const initialColumnOrder = columns.map((_, index) => index);
      setColumnOrder(initialColumnOrder);
    }
  }, [columns]);

  //While Edit Selected Store
  const toggleEditStore = (storeId) => {
    setIsOpenModalEditStore(true);
    const request = {
      storeId
    };
    dispatch(GetSelectedStoreData(request)); //Call API for fetching selected store details
  };

  const toggleDeleteModal = (data) => {
    setOpenDelete(!openDelete);
    setDeleteData(data);
  };

  const confirmDelete = () => {
    const request = {
      storeId: deleteData
    };
    dispatch(deleteStore(request)).then(() => {
      getStoreListWithPagination(page, rowPerPage, '', '', tableFilter);
      setOpenDelete(!openDelete);
    });
  };

  //For Resreshing Store Table once after Add or Edit Store
  const otherProps = {
    pageProps: {
      page,
      rowPerPage,
      changedColumn,
      direction,
      tableFilter
    }
  };

  return (
    <MainCard title="Store Management">
      <Box sx={{ display: 'flex', alignItems: 'end' }}>
        <Grid container sx={{ p: 3, pl: 0 }} spacing={3}>
          <Grid item xs={12} md={3.5}>
            <CustomAutocomplete
              multiple
              id="checkboxes-tags-demo"
              name="StoreName"
              label="Store Name"
              className="custom-auto"
              optionLabelkey="partner_name"
              optionIdkey="id"
              options={AllStorDataAdvertiser || []}
              value={filterValue?.partner_name || []}
              onOpen={() => setIsDropdownOpen(true)}
              onClose={() => setIsDropdownOpen(false)}
              inputValue={isDropdownOpen ? searchText?.partner_name : ''}
              onInputChange={(event, value, reason) => {
                if (reason !== 'reset') {
                  setSearchText({ ...searchText, partner_name: value });
                }
              }}
              onChange={(e, newValue) => {
                handleAutoCompleteOnChange(newValue, 'partner_name');
              }}
              selectAllCheckboxCondiion={handleAutoCompleteSelectAll('partner_name')}
              placeholderCondition={filterValue?.partner_name?.length > 0}
            />
          </Grid>
          <Grid item xs={12} md={3.5}>
            <CustomAutocomplete
              multiple
              id="checkboxes-tags-demo"
              name="DisplayName"
              label="Display Name"
              className="custom-auto"
              optionLabelkey="partner_display_name"
              optionIdkey="id"
              options={AllStorDataAdvertiser || []}
              value={filterValue?.partner_display_name || []}
              onOpen={() => setIsDropdownOpenDisplay(true)}
              onClose={() => setIsDropdownOpenDisplay(false)}
              inputValue={isDropdownOpenDisplay ? searchText?.partner_display_name : ''}
              onInputChange={(event, value, reason) => {
                if (reason !== 'reset') {
                  setSearchText({ ...searchText, partner_display_name: value });
                }
              }}
              onChange={(e, newValue) => {
                handleAutoCompleteOnChange(newValue, 'partner_display_name');
              }}
              selectAllCheckboxCondiion={handleAutoCompleteSelectAll('partner_display_name')}
              placeholderCondition={filterValue?.partner_display_name?.length > 0}
            />
          </Grid>
          <Grid item xs={12} md={3.5}>
            <CustomAutocomplete
              multiple
              id="checkboxes-tags-demo"
              name="Nick Name"
              label="Nick Name"
              className="custom-auto"
              optionLabelkey="nick_name"
              optionIdkey="id"
              options={AllStorDataAdvertiser || []}
              value={filterValue?.nick_name || []}
              onOpen={() => setIsDropdownOpenNick(true)}
              onClose={() => setIsDropdownOpenNick(false)}
              inputValue={isDropdownOpenNick ? searchText?.nick_name : ''}
              onInputChange={(event, value, reason) => {
                if (reason !== 'reset') {
                  setSearchText({ ...searchText, nick_name: value });
                }
              }}
              onChange={(e, newValue) => {
                handleAutoCompleteOnChange(newValue, 'nick_name');
              }}
              selectAllCheckboxCondiion={handleAutoCompleteSelectAll('nick_name')}
              placeholderCondition={filterValue?.nick_name?.length > 0}
            />
          </Grid>
          <Grid item xs={12} md={3.5}>
            <CustomAutocomplete
              multiple
              id="checkboxes-tags-demo"
              name="Type"
              label="Type"
              className="custom-auto"
              optionLabelkey="displayValue"
              optionIdkey="id"
              options={Seller_Type || []}
              value={filterValue?.seller_type || []}
              onOpen={() => setIsDropdownOpenType(true)}
              onClose={() => setIsDropdownOpenType(false)}
              inputValue={isDropdownOpenType ? searchText?.seller_type : ''}
              onInputChange={(event, value, reason) => {
                if (reason !== 'reset') {
                  setSearchText({ ...searchText, seller_type: value });
                }
              }}
              onChange={(e, newValue) => {
                handleAutoCompleteOnChange(newValue, 'seller_type');
              }}
              selectAllCheckboxCondiion={handleAutoCompleteSelectAll('seller_type')}
              placeholderCondition={filterValue?.seller_type?.length > 0}
            />
          </Grid>
          <Grid item xs={12} md={3.5}>
            <CustomAutocomplete
              multiple
              id="checkboxes-tags-demo"
              name="Walmart ID"
              label="Walmart ID"
              className="custom-auto"
              optionLabelkey="partner_store_id"
              optionIdkey="id"
              options={AllStorDataAdvertiser || []}
              value={filterValue?.partner_store_id || []}
              onOpen={() => setIsDropdownOpenWalmart(true)}
              onClose={() => setIsDropdownOpenWalmart(false)}
              inputValue={isDropdownOpenWalmart ? searchText?.partner_store_id : ''}
              onInputChange={(event, value, reason) => {
                if (reason !== 'reset') {
                  setSearchText({ ...searchText, partner_store_id: value });
                }
              }}
              onChange={(e, newValue) => {
                handleAutoCompleteOnChange(newValue, 'partner_store_id');
              }}
              selectAllCheckboxCondiion={handleAutoCompleteSelectAll('partner_store_id')}
              placeholderCondition={filterValue?.partner_store_id?.length > 0}
            />
          </Grid>
        </Grid>
        <Box sx={{ paddingBottom: '24px' }}>
          <Button variant="contained" onClick={handleSearchFilter} color="secondary">
            <SearchIcon sx={{ mr: 0.5 }} />
            Search
          </Button>
        </Box>
      </Box>

      {columns?.length === columnOrder?.length && (
        <BudgetManagerTable className="custom-budget-table-no-checkbox" data={newData} columns={columns} options={options} />
      )}

      {openDelete && (
        <DeleteModalDialog
          item="Store"
          cancelCallback={() => setOpenDelete(!openDelete)}
          deleteCallback={confirmDelete}
          open={openDelete}
        />
      )}
      {isOpenModalAddStore && (
        <CreateStore
          addNewStore={true}
          setIsOpenModalEditStore={setIsOpenModalEditStore}
          setIsOpenModalAddStore={setIsOpenModalAddStore}
          otherProps={otherProps}
        />
      )}
      {isOpenModalEditStore && (
        <ConnectWalmartSeller
          editStore={true}
          setIsOpenModalEditStore={setIsOpenModalEditStore}
          otherProps={otherProps}
          fixDisconnectedStore={fixDisconnectedStore}
          setFixDisconnectedStore={setFixDisconnectedStore}
        />
      )}
    </MainCard>
  );
};

export default StoreList;

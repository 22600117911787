import {
  // Autocomplete,
  Box,
  TextField,
  // Typography,
  // List,
  // ListItem,
  // ListItemText,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';
import * as React from 'react';
import { useState, useMemo, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { setCampaignDetails } from 'store/slices/userDetailsSlice';
import { CheckExistingRule, getCampaignDetailsRules, getRuleVariables, getCampaignNameList } from 'store/thunk/userThunk';

import MainCard from 'ui-component/cards/MainCard';
import CustomAutocomplete from 'ui-component/multi-select/CustomAutocomplete';

export default function ModuleDetails(props) {
  const { paramData, setParams, showError } = props;
  const dispatch = useAppDispatch();
  const { campaignNameList, commonDateRange, campaignDetails, commonAdvertiser } = useAppSelector(
    (state) => state?.userDetailsSlice
  );
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [searchText, setSearchText] = useState({
    campaign_search: null
  });
  const [isSelectAll, setIsSelectAll] = useState({
    campaign_search: []
  });
  const [data, setData] = useState({
    campaignsList: []
  });

  useEffect(() => {
    if (campaignDetails?.results?.length > 0) {
      setData({ ...data, campaignsList: campaignDetails?.results });
    }
  }, [campaignDetails?.results]);

  useEffect(() => {
    if (paramData?.moduleName) {
      dispatch(getRuleVariables({ moduleName: paramData?.moduleName }));
    }
  }, []);

  useMemo(() => {
    if (commonAdvertiser?.length > 0) {
      dispatch(getCampaignNameList({ id: commonAdvertiser }));
      dispatch(getCampaignDetailsRules({ page: 0, rowPerPage: 1000, advertiserFilter: commonAdvertiser, commonDateRange }));
    } else {
      setParams({ ...paramData, profile: null, selectedCampaign: null });
    }
  }, [commonAdvertiser]);

  useMemo(() => {
    if (paramData?.selectedCampaign?.id) {
      setData({ ...data, campaignData: paramData?.selectedCampaign });
    } else {
      setData({ ...data, campaignDetails: null });
      dispatch(setCampaignDetails(null));
    }
  }, [paramData?.selectedCampaign]);

  // const CampaignDetails = (data) => {
  //   if (data?.id) {
  //     return (
  //       <Box sx={{ px: 4 }}>
  //         <Typography variant="h4" gutterBottom>
  //           Campaign Details
  //         </Typography>
  //         <Grid container spacing={4}>
  //           {/* Left Column */}
  //           <Grid item xs={12} sm={6}>
  //             <List>
  //               <ListItem>
  //                 <ListItemText primary="Online Ad SKU ROAS" secondary={data?.roas || 'N/A'} />
  //               </ListItem>
  //               <ListItem>
  //                 <ListItemText primary="Online Ad SKU ACOS" secondary={data?.acos || 'N/A'} />
  //               </ListItem>
  //               <ListItem>
  //                 <ListItemText primary="Online Sale Units" secondary={data?.units_sold || 'N/A'} />
  //               </ListItem>
  //               <ListItem>
  //                 <ListItemText primary="Clicks" secondary={data?.num_ads_clicks || 'N/A'} />
  //               </ListItem>
  //               <ListItem>
  //                 <ListItemText primary="CTR" secondary={data?.ctr || 'N/A'} />
  //               </ListItem>
  //               <ListItem>
  //                 <ListItemText primary="CVR (Orders)" secondary={data?.cvr_orders || 'N/A'} />
  //               </ListItem>
  //               <ListItem>
  //                 <ListItemText primary="CVR (Units)" secondary={data?.cvr_units || 'N/A'} />
  //               </ListItem>
  //               <ListItem>
  //                 <ListItemText primary="NTB Sales(%)" secondary={data?.percentage_new_brand_sales || 'N/A'} />
  //               </ListItem>
  //             </List>
  //           </Grid>
  //         </Grid>
  //       </Box>
  //     );
  //   }
  //   return null;
  // };

  const handleChange = (event) => {
    setParams({ ...paramData, moduleName: event.target.value });
    dispatch(getRuleVariables({ moduleName: event.target.value }));
  };

  const handleRuleBlur = (value) => {
    if (value?.length) {
      dispatch(CheckExistingRule({ _request: { rule_name: value } }));
    }
  };

  const handleAutoCompleteOnChange = (newValue) => {
    const baseArray = [...campaignNameList];

    //Check if Select All checkbox is slected
    if (newValue.find((option) => option.all)) {
      const searchTerm = searchText?.['campaign_search']?.toLowerCase() || ''; //Change as per selected filter
      const filteredCampaigns = searchTerm
        ? baseArray.filter((item) => item?.name?.toLowerCase().includes(searchTerm))
        : baseArray;
      const allIdsCam = filteredCampaigns.map((obj) => obj.id);

      setIsSelectAll((prevState) => ({
        ...prevState,
        ['campaign_search']: filteredCampaigns
      }));

      setParams((prevState) => ({
        ...prevState,
        ['selectedCampaign']: newValue.length - 1 === allIdsCam.length ? [] : filteredCampaigns
      }));
    } else {
      setParams((prevState) => ({
        ...prevState,
        ['selectedCampaign']: newValue
      }));
    }
  };

  const handleAutoCompleteSelectAll = () => {
    const baseArray = [...campaignNameList];

    const status =
      paramData?.['selectedCampaign']?.length && isSelectAll?.['campaign_search']?.length
        ? !!(paramData?.['selectedCampaign']?.length == baseArray?.length) ||
          !!(paramData?.['selectedCampaign']?.length == isSelectAll?.['campaign_search']?.length)
        : false;

    return status;
  };

  return (
    <MainCard>
      <Grid container spacing={2}>
        <Grid item md={6}>
          <Box mb={2}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Module name</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={paramData?.moduleName || ''}
                label="Module Name"
                onChange={handleChange}
              >
                <MenuItem value="Campaign">Campaigns</MenuItem>
                {/* <MenuItem value="Keyword">keywords</MenuItem>
                <MenuItem value="Item">Items</MenuItem> */}
              </Select>
            </FormControl>
          </Box>
          <Box mb={2}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="Rule Name"
              variant="outlined"
              onChange={(e) => setParams({ ...paramData, ruleName: e.target.value })}
              value={paramData?.ruleName || ''}
              onBlur={(e) => handleRuleBlur(e.target.value)}
              error={showError && paramData?.moduleName?.length}
              helperText={
                showError && paramData?.moduleName?.length ? 'The rule name is already taken. Please choose a different one.' : ''
              }
            />
          </Box>
          <Box mb={2}>
            <CustomAutocomplete
              multiple
              id="checkboxes-tags-demo"
              name="Campaign"
              label="Campaign"
              className="custom-auto"
              optionLabelkey="name"
              optionIdkey="id"
              options={campaignNameList || []}
              value={paramData?.selectedCampaign || []}
              onOpen={() => setIsDropdownOpen(true)}
              onClose={() => setIsDropdownOpen(false)}
              inputValue={isDropdownOpen ? searchText?.campaign_search : ''}
              onInputChange={(event, value, reason) => {
                if (reason !== 'reset') {
                  setSearchText({ ...searchText, campaign_search: value });
                }
              }}
              onChange={(e, newValue) => {
                handleAutoCompleteOnChange(newValue, 'campaign_search');
              }}
              // onChange={(e, newValue) => {
              //   if (newValue.find((option) => option.all)) {
              //     setParams({ ...paramData, selectedCampaign: '' });
              //     return setParams({
              //       ...paramData,
              //       selectedCampaign: newValue.length - 1 === campaignNameList.length ? [] : campaignNameList
              //     });
              //   } else {
              //     setParams({ ...paramData, selectedCampaign: newValue });
              //   }
              // }}
              selectAllCheckboxCondiion={handleAutoCompleteSelectAll()}
              placeholderCondition={paramData?.selectedCampaign?.length > 0}
            />
          </Box>
          <Box mb={2}>
            <TextField
              multiline
              fullWidth
              rows={3}
              id="outlined-basic"
              label="Rule Description"
              variant="outlined"
              onChange={(e) => setParams({ ...paramData, description: e.target.value })}
              value={paramData?.description || ''}
            />
          </Box>
        </Grid>
      </Grid>
    </MainCard>
  );
}
